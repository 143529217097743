import Vue from 'vue'
let store = null

// =====================================================================================
// Customer Mgmt modules helpers (non persist)
// =====================================================================================

class CustomerMgmtStoreHelper {
  namespace = 'customer'

  constructor() {
    store = Vue.$store
  }

  getCustomers = async (request) => {
    return store.dispatch(`${this.namespace}/getCustomers`, request)
  }

  getCountries = async (request) => {
    return store.dispatch(`${this.namespace}/getCountries`, request)
  }

  getCountriesForNewCustomer = async (request) => {
    return store.dispatch(`${this.namespace}/getCountriesForNewCustomer`)
  }

  createCustomer = async (request) => {
    return store.dispatch(`${this.namespace}/createCustomer`, request)
  }

  editCustomer = async (request) => {
    return store.dispatch(`${this.namespace}/editCustomer`, request)
  }

  getDevices = async (request) => {
    return store.dispatch(`${this.namespace}/getDevices`, request)
  }

  getSelectedDevices = async (request) => {
    return store.dispatch(`${this.namespace}/getSelectedDevices`, request)
  }

  postSelectedDevices = async (request) => {
    return store.dispatch(`${this.namespace}/postSelectedDevices`, request)
  }
}

export const customerMgmtStoreHelper = new CustomerMgmtStoreHelper()
