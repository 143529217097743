import { lazyLoadView } from '@baxter/web-vueapp-lib'

export default [
  {
    path: '/customer_management',
    pathToRegexpOptions: { strict: true },
    component: () =>
      lazyLoadView(import('@plugins/customer-management/router/views/index')),
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: '',
        name: 'customer_management_customers',
        component: () =>
          lazyLoadView(
            import('@plugins/customer-management/components/customers')
          ),
      },
      {
        path: 'customer',
        component: () =>
          lazyLoadView(
            import('@plugins/customer-management/components/customers')
          ),
      },
    ],
  },
]
