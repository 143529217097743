/* eslint-disable */ 
import { buildQueryString } from '@baxter/web-utils'
import webClientAuth from '@baxter/web-client-auth'
import Vue from 'vue'
import axios from 'axios'
// ============================
// State
// ============================

export const state = {}

// ============================
// Getters
// ============================

export const getters = {}

const apiVersion = '/v2'
// ============================
// Actions
// ============================
export const actions = {
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  init({ state, dispatch, commit }) {},

  async getCustomers({ commit, state, rootState, dispatch }, request) {
    const endPoint = `${
      Vue.$APP_CONFIG.api_base_url
    }${apiVersion}/customers?${buildQueryString(request)}`
    let response = null
    response = await axios.get(endPoint);
    // response = await webClientAuth.httpRequest('GET', endPoint)
    return response.data
  },

  async getCountries({ commit, state, rootState, dispatch }, request) {
    const endPoint = `${
      Vue.$APP_CONFIG.api_base_url
    }${apiVersion}/customers?${buildQueryString(request)}`
    let response = null

    // webClientAuth.startup()
    response = await axios.get(endPoint);
    // response = await webClientAuth.httpRequest('GET', endPoint)
    return response.data
  },

  async getCountriesForNewCustomer({ commit, state, rootState, dispatch },request) {
    const endPoint = `${Vue.$APP_CONFIG.api_base_url}${apiVersion}/countries`
    let response = null
    // webClientAuth.startup()
    response = await axios.get(endPoint);
    // response = await webClientAuth.httpRequest('GET', endPoint, {})
    return response.data
  },

  async createCustomer({ commit, state, rootState, dispatch }, request) {
    let actions = 'POST'
    let endPoint = `${Vue.$APP_CONFIG.api_base_url}${apiVersion}/customers`

    if (request.id) {
      endPoint = `${endPoint}/${request.id}`
      actions = 'PUT'
    }
    let response = null
    response = await axios({
      method: actions.toLowerCase(),
      url: endPoint,
      data: request
    });
    // webClientAuth.startup()
    // response = await webClientAuth.httpRequest(actions, endPoint, request)
    return response.data
  },

  async editCustomer({ commit, state, rootState, dispatch }, request) {
    const endPoint = `${Vue.$APP_CONFIG.api_base_url}${apiVersion}/customers/${request.id}`
    let response = null

    // webClientAuth.startup()
    response = await axios.put(endPoint,request);
    // response = await webClientAuth.httpRequest('PUT', endPoint, request)
    return response.data
  },

  async getDevices({ commit, state, rootState, dispatch }, request) {
    const endPoint = `${
      Vue.$APP_CONFIG.api_base_url
    }${apiVersion}/deviceManagement/devices?${buildQueryString(request)}`
    let response = null
    // webClientAuth.startup()
    response = await axios.get(endPoint);
    // response = await webClientAuth.httpRequest('GET', endPoint)
    return response.data
  },

  async getSelectedDevices({ commit, state, rootState, dispatch }, request) {
    const { therapy_device_id, gateway_serial_number } = request
    const endPoint = `${Vue.$APP_CONFIG.api_base_url}${apiVersion}/devices/${therapy_device_id}/gateways/${gateway_serial_number}/settings`
    let response = null
    // webClientAuth.startup()
    response = await axios.get(endPoint);
    // response = await webClientAuth.httpRequest('GET', endPoint)
    return response.data
  },

  async postSelectedDevices({ commit, state, rootState, dispatch }, request) {
    let response = null
    let endPoint
    const { customerId } = request
    delete request.customerId

    endPoint = `${Vue.$APP_CONFIG.api_base_url}${apiVersion}/customers/${customerId}/devices/gateways/settings`

    // webClientAuth.startup()
    response = await axios.put(endPoint, request);
    // response = await webClientAuth.httpRequest('PUT', endPoint, request)
    return response.data
  },
}

// ============================
// Mutations
// ============================
export const mutations = {}
