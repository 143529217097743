<!-- eslint-disable -->
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-row justify="start" class="layoutmargin">
          <v-col class="xs ml-3" cols="6" sm="6" md="4" lg="4" xl="4">
            <!-- Country filter dropdown -->
            <v-combobox
              id="country"
              v-model="selectedCountry"
              :items="countries"
              :label="
                $t(
                  'apps.customer_management.customer.filters.country_filter_label'
                )
              "
              outlined
              dense
              item-text="country"
              item-value="country"
              :return-object="false"
              @change="filter_Changed"
            ></v-combobox>
          </v-col>
          <!-- Create New Customer Button -->
          <v-col class="xs" cols="6" sm="6" md="4" lg="4" xl="4">
            <v-btn
              id="newcustomerbutton"
              class="newcustomerbutton"
              @click="createCustomer"
            >
              {{
                $t('apps.customer_management.customer.register_new_customer')
              }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="end" class="layoutmargin">
          <!-- Search Text field -->
          <v-col class="xs" cols="6" sm="6" md="4" lg="3" xl="3">
            <v-text-field
              id="searchtext"
              v-model="searchText"
              solo
              class="elevation-0"
              clearable
              clear-icon="cancel"
              :placeholder="
                $t('apps.customer_management.customer.filters.search')
              "
              hide-details
              append-icon="search"
              @keyup.enter="
                gridConfig.options.page = 1
                refreshGrid()
              "
              @click:append="
                gridConfig.options.page = 1
                refreshGrid()
              "
              @click:clear="
                searchText = ''
                gridConfig.options.page = 1
                refreshGrid()
              "
            ></v-text-field>
          </v-col>
          <!-- Select additionsl columns -->
          <v-col class="xs mr-3" cols="6" sm="6" md="6" lg="5" xl="5">
            <v-combobox
              id="additionalcolumn"
              v-model="selectedColumns"
              :items="nonMandatoryColumList"
              :label="
                $t(
                  'apps.customer_management.customer.filters.column_dropdown_label'
                )
              "
              multiple
              outlined
              dense
              @change="column_Changed"
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="subtitle-2">{{
                  item.text
                }}</span>
                <span v-if="index === 1" class="caption"
                  >(+{{ selectedColumns.length - 1 }} others)</span
                >
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <!-- Customer list grid -->
        <v-col
          class="mx-4 pr-12 xs xs"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <WebDataGrid
            id="customerlist"
            :grid-config="gridConfig"
            @refresh-grid-event="refreshGrid"
            @pagination-next-click-event="refreshGrid"
            @pagination-previous-click-event="refreshGrid"
          >
            <!-- Show comma separated JDE Ids -->
            <template v-slot:item.jde_id="{ item }">{{
              normalizeJDEId(item.jde_id)
            }}</template>

            <!-- Show Edit WebLinkButton -->
            <template v-slot:item.edit="{ item }">
              <WebLinkButton
                id="edit"
                ref="editButton"
                class="editButton"
                @click="editCustomer(item)"
              >
                {{ $t('apps.customer_management.customer.columns.edit') }}
              </WebLinkButton>
            </template>
            <!-- Show Devices WebLinkButton -->
            <template v-slot:item.device="{ item }">
              <WebLinkButton id="showdevices" @click="showDevices(item)">
                {{ $t('apps.customer_management.customer.columns.devices') }}
              </WebLinkButton>
            </template>
            <!-- Show Status -->
            <template v-slot:item.is_active="{ item }">
              {{
                item.is_active
                  ? $t('apps.customer_management.customer.columns.active')
                  : $t('apps.customer_management.customer.columns.inactive')
              }}
            </template>
            <!-- Show create date in proper format -->
            <template v-slot:item.create_ts="{ item }">{{
              normalizeDate(item.create_ts)
            }}</template>
            <!-- Show updated date in proper format -->
            <template v-slot:item.update_ts="{ item }">{{
              normalizeDate(item.update_ts)
            }}</template>
            <!-- Show begin date in proper format -->
            <template v-slot:item.eff_begin_date="{ item }">{{
              normalizeDate(item.eff_begin_date)
            }}</template>
            <!-- Show end date in proper format -->
            <template v-slot:item.eff_end_date="{ item }">{{
              normalizeDate(item.eff_end_date)
            }}</template>
          </WebDataGrid>
        </v-col>
      </v-row>
    </v-col>
    <!-- Show Create New Customer page -->
    <v-dialog
      v-if="dialog"
      id="createcustomer"
      v-model="dialog"
      :width="700"
      :height="700"
    >
      <CreateNewCustomer
        :customer="customer"
        :is-edit="isEdit"
        @closeCustomerDailog="closeCustomerDailog($event)"
      ></CreateNewCustomer>
    </v-dialog>
    <v-dialog
      v-if="devicesDialog"
      id="devicedialog"
      v-model="devicesDialog"
      width="1200"
    >
      <devices
        :key="deviceDialogCustomer.id"
        :customer-name="deviceDialogCustomer.name"
        :customer-id="deviceDialogCustomer.id"
        @hideDevices="hideDevices"
      >
      </devices>
    </v-dialog>

    <!-- Alert message -->
    <v-snackbar
      v-model="showSnackbar"
      top="top"
      :color="alertColor"
      :timeout="messageTimeout"
    >
      {{ alertMessage }}
      <v-btn color="black" text @click="showSnackbar = false">{{
        $t('apps.core.base_alert.ok_button')
      }}</v-btn>
    </v-snackbar>
  </v-row>
</template>
<script>
/* eslint-disable */ 
import { customerMgmtStoreHelper } from '@plugins/customer-management/state/helpers'
import { formatDateTimeRelative } from '@baxter/web-utils'
import customerConstants from '../constants/const'
import CreateNewCustomer from './create_edit_customer.vue'
import devices from './devices.vue'
import moment from 'moment-timezone'
import 'moment-timezone'

export default {
  components: {
    CreateNewCustomer,
    devices,
  },
  data: () => ({
    devicesDialog: false,
    deviceDialogCustomer: '',
    dialog: false,
    selectedColumns: [],
    selectedCountry: '',
    searchText: '',
    nonMandatoryColumList: [],
    countries: [],
    isEdit: false,
    messageTimeout: customerConstants.messageTimeout,
    customer: {
      name: '',
      email: '',
      eff_begin_date: '',
      eff_end_date: '',
      language: '',
      jde_id: '',
      country: '',
      country_code: '',
      state: '',
      currency: '',
      phone: null,
      address_line_1: '',
      address_line_2: '',
      zip: null,
    },
    gridConfig: {
      records: [],
      loading: true,
      itemKey: customerConstants.fields.id,
      headers: [],
      highlightKey: customerConstants.fields.id,
      showSelect: false,
      options: {
        itemsPerPageOptions: customerConstants.numberOfRecordsPerPage,
        totalPages: 0,
        itemsPerPage: customerConstants.numberOfRecordsPerPage[0],
        page: 1,
        descending: false,
      },
    },
    alertMessage: null,
    showSnackbar: false,
    alertColor: 'success',
  }),
  async created() {
    this.fillNonMandatoryHeaders()
    this.gridConfig.headers = this.getHeaders()
    await this.getCountries()
  },

  methods: {
    // Close Customer dialoag and refresh the grid
    closeCustomerDailog($paylod) {
      this.dialog = false
      if (!$paylod.isEdit) this.gridConfig.options.page = 1
      if ($paylod.isSuccess) {
        var alertMessage = ''
        if ($paylod.isEdit)
          alertMessage = this.$t(
            'apps.create_customer.messages.edit_customer_success'
          )
        else {
          alertMessage = this.$t(
            'apps.create_customer.messages.add_customer_success'
          )
        }
        this.alertMessage = alertMessage
        this.alertColor = 'success'
        this.showSnackbar = true
        this.refreshGrid()
      }
    },
    // Open Create New Customer dialog
    createCustomer() {
      this.customer = this.defaultCutomerObj()
      this.isEdit = false
      this.dialog = true
    },
    // Normalize the dates in grid
    normalizeDate(iValue) {
      if (iValue) return moment.tz(String(iValue), 'UTC').format('LL')
      return iValue
    },
    // Normalize the JDE Id list in grid
    normalizeJDEId(jdeidList) {
      return jdeidList
        .map((item, index) => {
          return item.jde_id
        })
        .join(', ')
    },
    // Open separate window to edit the Customer.
    editCustomer(item) {
      this.customer = Object.assign({}, item)
      this.isEdit = true

      //* ** Adding IsEdit Flag in JdeList for UI
      this.customer.jde_id = this.customer.jde_id.map((item, index) => {
        return {
          jde_id: item.jde_id,
          is_primary: item.is_primary,
          is_edit: true,
        }
      })
      this.dialog = true
    },
    showDevices(customer) {
      this.deviceDialogCustomer = customer
      this.devicesDialog = true
    },
    hideDevices() {
      this.devicesDialog = false
    },
    // List of columns to display in Additional Columns dropdown
    fillNonMandatoryHeaders: function() {
      this.nonMandatoryColumList = [
        {
          text: this.$t(
            'apps.customer_management.customer.columns.country_code'
          ),
          value: customerConstants.fields.country_code,
          sortable: false,
          id: 11,
        },
        {
          text: this.$t(
            'apps.customer_management.customer.columns.address_line_1'
          ),
          value: customerConstants.fields.address_line_1,
          sortable: false,
          id: 12,
        },
        {
          text: this.$t(
            'apps.customer_management.customer.columns.address_line_2'
          ),
          value: customerConstants.fields.address_line_2,
          sortable: false,
          id: 13,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.city'),
          value: customerConstants.fields.city,
          sortable: false,
          id: 14,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.state'),
          value: customerConstants.fields.state,
          sortable: false,
          id: 15,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.zip'),
          value: customerConstants.fields.zip,
          sortable: false,
          id: 16,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.currency'),
          value: customerConstants.fields.currency,
          sortable: false,
          id: 17,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.language'),
          value: customerConstants.fields.language,
          sortable: false,
          id: 18,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.begin_date'),
          value: customerConstants.fields.eff_begin_date,
          sortable: false,
          id: 19,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.end_date'),
          value: customerConstants.fields.eff_end_date,
          sortable: false,
          id: 20,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.created_by'),
          value: customerConstants.fields.created_by,
          sortable: false,
          id: 21,
        },
        {
          text: this.$t(
            'apps.customer_management.customer.columns.updated_date'
          ),
          value: customerConstants.fields.update_ts,
          sortable: false,
          id: 22,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.updated_by'),
          value: customerConstants.fields.updated_by,
          sortable: false,
          id: 23,
        },
      ]
    },
    // List of Headers for default headers in Grid
    getHeaders: function() {
      var arrColumns = [
        {
          text: this.$t('apps.customer_management.customer.columns.name'),
          value: customerConstants.fields.name,
          sortable: false,
          id: 1,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.action'),
          value: customerConstants.fields.edit,
          sortable: false,
          id: 2,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.id'),
          value: customerConstants.fields.id,
          sortable: false,
          id: 3,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.jde_id'),
          value: customerConstants.fields.jde_id,
          sortable: false,
          id: 4,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.country'),
          value: customerConstants.fields.country,
          sortable: false,
          id: 5,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.phone'),
          value: customerConstants.fields.phone,
          sortable: false,
          id: 6,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.email'),
          value: customerConstants.fields.email,
          sortable: false,
          id: 7,
        },
        {
          text: this.$t(
            'apps.customer_management.customer.columns.created_date'
          ),
          value: customerConstants.fields.create_ts,
          sortable: false,
          id: 8,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.status'),
          value: customerConstants.fields.is_active,
          sortable: false,
          id: 9,
        },
        {
          text: this.$t('apps.customer_management.customer.columns.devices'),
          value: customerConstants.fields.device,
          sortable: false,
          id: 10,
        },
      ]
      // Update the header list depends on the selection of additional columns
      this.selectedColumns.forEach((item) => {
        arrColumns.push(
          ...this.nonMandatoryColumList.filter(
            (col) => col.value === item.value
          )
        )
      })
      arrColumns = arrColumns.slice().sort(function(headerA, headerB) {
        return headerA.id - headerB.id
      })
      return arrColumns
    },

    // Fetch list of countries for filter dropdown
    async getCountries() {
      try {
        const field = customerConstants.fields.country // get the unique country list
        const request = {
          fields: field,
          unique: true,
        }

        const response = await customerMgmtStoreHelper.getCountries(request)
        this.countries = response.customers

        // Add additional row at top to select All Countries
        this.countries.unshift(customerConstants.allTextInCountryDropdown)
        this.selectedCountry = this.countries[0]
      } catch (exception) {
        this.alertMessage = this.$t(
          'apps.core.base_alert.dataloading_error_message'
        )
        this.alertColor = 'error'
        this.showSnackbar = true
      }
    },

    // On Country filter changed
    filter_Changed() {
      this.gridConfig.options.page = 1
      this.refreshGrid()
    },

    // Common method to get list of Customers from Server
    async refreshGrid() {
      try {
        const pageSize = this.gridConfig.options.itemsPerPage
        const pageNumber = this.gridConfig.options.page
        const request = {
          pagination:
            customerConstants.querystring.page +
            '~' +
            pageNumber +
            ',' +
            customerConstants.querystring.pagesize +
            '~' +
            pageSize,
        }

        // filter the data
        if (
          this.selectedCountry !== '' &&
          this.selectedCountry !== customerConstants.allTextInCountryDropdown
        ) {
          request[customerConstants.querystring.filter] =
            customerConstants.fields.country + '~' + this.selectedCountry
        }

        if (
          this.searchText !== null &&
          (this.searchText.includes('"') ||
            this.searchText.includes('\\') ||
            this.searchText.includes("\\'") ||
            this.searchText.includes('%'))
        ) {
          throw 'not allowed characters entered in Search'
        }

        // Search the data
        if (this.searchText !== null && this.searchText !== '') {
          request[customerConstants.querystring.search] = encodeURIComponent(
            this.searchText
          )
        }

        // make a call
        // data loading started
        this.gridConfig.loading = true

        const response = await customerMgmtStoreHelper.getCustomers(request)
        this.gridConfig.records = response.customers
        this.gridConfig.options.totalItems = parseInt(response.totalRecords)
        this.gridConfig.options.totalPages = Math.ceil(
          parseInt(this.gridConfig.options.totalItems) / pageSize
        )
      } catch (exception) {
        this.gridConfig.loading = false
        this.alertMessage = this.$t(
          'apps.core.base_alert.dataloading_error_message'
        )
        if (exception.includes('not allowed characters entered in Search')) {
          this.alertMessage = this.$t(
            'apps.core.base_alert.search_character_not_allowed'
          )
        }
        this.alertColor = 'error'
        this.showSnackbar = true
      }

      // data api call is finished here, turn off the progress bar
      this.gridConfig.loading = false
    },

    // Show/hide columns to/from customer grid
    column_Changed: function() {
      this.gridConfig.headers = this.getHeaders()
      this.$forceUpdate()
    },
    defaultCutomerObj() {
      return {
        name: '',
        email: '',
        eff_begin_date: null,
        eff_end_date: null,
        language: '',
        jde_id: [],
        country: '',
        country_code: '',
        state: '',
        currency: null,
        currency_code: '',
        phone: null,
        address_line_1: '',
        address_line_2: '',
        zip: null,
        is_active: false,
      }
    },
  },
}
</script>
<style lang="scss">
.background {
  background-color: rgba(84, 88, 90, 0.1) !important;
  vertical-align: middle !important;
  min-height: 60px !important;
  margin: auto !important;
  padding: 10px !important;
}
.layoutmargin {
  margin: 0 !important;
}
.formComponentMargin {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}
.form_buttons {
  height: 35px;
  min-height: 35px !important;
}
.error_label {
  color: red;
  margin-bottom: 0px;
  line-height: 0px;
}
.newcustomerbutton {
  height: 2.75em !important;
  min-height: 2.75em !important;
}
.background_Color {
  background-color: red;
}
//search box
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
}

//DropDown label color
.v-text-field--outlined .v-label--active {
  color: #003399 !important;
}

//Select Column checkbox gap
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 3px;
}
</style>
