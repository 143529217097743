<!-- eslint-disable -->
<template>
  <div>
    <v-form ref="Createform" @submit.prevent="submit">
      <v-card>
        <v-container>
          <!-- Change the dialog title depends on the mode -->
          <v-card-title v-if="isEdit">
            <span class="headline">{{
              $t('apps.create_customer.edit_dialog_title')
            }}</span>
            <br />
          </v-card-title>
          <v-card-title v-else>
            <span class="headline">{{
              $t('apps.create_customer.dialog_title')
            }}</span>
          </v-card-title>

          <!-- Show Customer details if its Edit mode -->
          <div v-if="isEdit" class="subheader subtitle-2">
            <v-row class="xs6 md12 lg12 xl12">
              <v-col
                class="color justify"
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                >{{
                  $t('apps.create_customer.customer_key_label') +
                    ': ' +
                    customer.id
                }}</v-col
              >
              <v-col
                class="color justify"
                cols="12"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                >{{
                  $t('apps.create_customer.created_date_label') +
                    ': ' +
                    createdDate
                }}</v-col
              >
            </v-row>
          </div>

          <!-- Components to Add/Edit Customer details -->
          <v-card-text>
            <v-row class="xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6">

                <validation-provider rules="nameError" name="name" v-slot="{ errors }">
                  <v-text-field
                  id="name"
                  v-model="customer.name"
                  name="Name"
                  class="name"
                  :label="$t('apps.create_customer.customer_name')"
                  required
                  outlined
                ></v-text-field>
                <span v-if="errors[0]" class="error_label">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

               <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                 <validation-provider rules="emailError|emailvalidation" name="email" v-slot="{ errors }">
                  <v-text-field
                  id="email"
                  v-model="customer.email"
                  name="Email"
                  :label="$t('apps.create_customer.email')"
                  outlined
                  required
                ></v-text-field>
                <span v-if="errors[0]" class="error_label">{{ errors[0] }}</span>
                </validation-provider>
              </v-col> 
            </v-row>
           
            <v-row class="xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="city"
                  v-model="customer.city"
                  name="City"
                  :label="$t('apps.create_customer.city')"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">

                <v-text-field
                  id="phone"
                  v-model="customer.phone"
                  name="Phone"
                  :label="$t('apps.create_customer.phone')"
                  outlined
                ></v-text-field>

              </v-col>
            </v-row>
 
            <v-row class="xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="addressline1"
                  v-model="customer.address_line_1"
                  name="Address1"
                  :label="$t('apps.create_customer.address_line_1')"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="addressline2"
                  v-model="customer.address_line_2"
                  name="Address2"
                  :label="$t('apps.create_customer.address_line_2')"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="state"
                  v-model="customer.state"
                  name="State"
                  :label="$t('apps.create_customer.state')"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="zip"
                  v-model="customer.zip"
                  name="Zip"
                  :label="$t('apps.create_customer.zip')"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>


            <!-- NEED TO CHECK -->
            <v-row class="xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
              <validation-provider rules="countryError" name="country" v-slot="{ errors }">
                <v-autocomplete
                  id="country"
                  v-model="customer.country"
                  :label="$t('apps.create_customer.country')"
                  name="Country"
                  :items="countries"
                  data-vv-name="country"
                  clearable
                  :disabled="isEdit"
                  outlined
                  @change="countryDropdownChange"
                ></v-autocomplete>
                <span v-if="errors[0]" class="error_label">{{ errors[0] }}</span>
                </validation-provider>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n4">
                <v-text-field
                  id="countrycode"
                  v-model="customer.country_code"
                  :disabled="disablefield"
                  :label="$t('apps.create_customer.country_code')"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>

          <v-row class="spacing xs6 md12 lg12 xl12" justify="space-between">
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n1">
                <v-autocomplete
                  id="language"
                  v-model="customer.language"
                  :label="$t('apps.create_customer.language')"
                  :disabled="disablefield"
                  outlined
                  :items="languages"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-n1">
                <v-autocomplete
                  id="currency"
                  v-model="customer.currency"
                  :disabled="disablefield"
                  :label="$t('apps.create_customer.currency')"
                  :items="currencies"
                  outlined
                />
              </v-col>
            </v-row>
             <v-row class="xs6 md12 lg12 xl12 my-n12" justify="space-between">

             

              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-9">
                <validation-provider rules="jdeidError" v-slot="{ errors }">
                <v-textarea
                  id="jdeid"
                  v-model="getJdeId"
                  name="jde_id"
                  :label="$t('apps.create_customer.jdeid_texfield')"
                  outlined
                  readonly
                  :disabled="isEdit"
                ></v-textarea>
                <span v-if="errors[0]" class="error_label">{{ errors[0] }}</span>
                </validation-provider>

              </v-col>

              <v-col cols="12" sm="6" md="6" lg="6" xl="6" class="mt-10">
                <v-row>
                  <v-switch
                    id="is_active"
                    v-model="is_active"
                    class="is_active"
                    :label="
                      $t('apps.customer_management.customer.columns.active')
                    "
                    @change="is_active_Changed"
                  >
                  </v-switch>
                </v-row>
                <v-row class="addjdeid">
                  <WebLinkButton id="addjdeid" @click="addJdeId">{{
                    $t('apps.create_customer.jdeid_button')
                  }}</WebLinkButton>
                </v-row>
              </v-col>
            </v-row> 
          </v-card-text>
          <br />
          <br />
          <v-card-actions class="mt-10">
            <!-- :disabled="errors.any()" -->
            <v-row class="xs6 md12 lg12 xl12" justify="center">
              <v-btn
                id="submit"
                type="submit"
                class="form_buttons"
                :disabled="clickable"
                color="primary"
                >{{ $t('apps.create_customer.save') }}</v-btn
              >
              <v-btn
                id="cancel"
                class="form_buttons"
                color="primary"
                @click="onCancelForm(false)"
                >{{ $t('apps.create_customer.cancel') }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-form>
    <!-- Loading indicator -->
    <WebDataLoading ref="loader"></WebDataLoading>
    <!-- JDE Dailog -->
    <v-dialog
      v-if="JDEdialog"
      id="jdedialog"
      v-model="JDEdialog"
      scrollable
      :max-width="485"
      :max-height="485"
    >
      <addJdeId
        :jde-ids-list-prop="JSON.parse(JSON.stringify(customer.jde_id))"
        @closeJDEDailog="closeJDEDailog($event)"
        @saveJDE="saveJDE($event)"
      ></addJdeId>
    </v-dialog>
    <!-- JDE Dailog -->

    <v-snackbar
      v-model="showSnackbar"
      top="top"
      :color="alert_color"
      :timeout="messageTimeout"
    >
      {{ alert_message }}
      <v-btn color="black" text @click="snackbar = false">{{
        $t('apps.core.base_alert.ok_button')
      }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable */
import Vue from 'vue'
import CustomerFormValidator from '@plugins/customer-management/validators/customerModel'
import { ValidationProvider, extend } from 'vee-validate';
import { required,email } from 'vee-validate/dist/rules';
import addJdeId from '@plugins/customer-management/components/jde_id_form'
import { customerMgmtStoreHelper } from '@plugins/customer-management/state/helpers'
import moment from 'moment-timezone'
import 'moment-timezone'
import customerConstants from '../constants/const'
import webClientAuth from '@baxter/web-client-auth'


export default {
  components: { addJdeId,ValidationProvider },
  mixins: [CustomerFormValidator],
  props: {
    customer: Object,
    isEdit: Boolean,
  },

  data: () => ({
    disablefield: true,
    countriesData: [],
    JDEdialog: false,
    beginDate_Menu: false,
    endDate_Menu: false,
    is_active: false,
    countries: [],
    currencies: [],
    languages: [],
    error_Messages: null,
    alert_message: null,
    showSnackbar: false,
    alert_color: 'success',
    messageTimeout: customerConstants.messageTimeout
  }),
  computed: {
    getJdeId() {
      return Object.values(this.customer.jde_id)
        .map((item, index) => {
          return item.jde_id
        })
        .join(', ')
    },
    createdDate() {
      return moment(this.customer.create_ts).format('LL')
    },
    clickable: function() {
    if(!this.customer.name || !this.customer.email || !this.customer.country || !this.getJdeId)
   { return true;}
   else return false
  }
  },
  mounted() {
    extend('nameError', {
      ...required,
      message: this.$t('apps.create_customer.error_messages.customername')
    });
    
    extend('emailError', {
      ...required,
      message: this.$t('apps.create_customer.error_messages.email')
    });
    extend('emailvalidation', { ...email,
      message: this.$t('apps.create_customer.error_messages.valid_email')
    });
    
    extend('countryError', {
      ...required,
      message: this.$t('apps.create_customer.error_messages.country')
    });


    extend('jdeidError', {
      ...required,
      message: this.$t('apps.create_customer.error_messages.jde_id')
    });
  },

  async created() {
    this.is_active = this.customer.is_active
    // Get list of countries and respective, Currency & Language
    await this.getCountries()
    const self = this
    this.disablefield = !this.isEdit
  },
  methods: {
    // Load list of countries and respective details
    async getCountries() {
      try {
        const response = await customerMgmtStoreHelper.getCountriesForNewCustomer()
        this.countriesData = response
        var countryList = this.countriesData.map(function(country) {
          return country.country
        })
        this.countries = countryList
        this.countryDropdownChange(this.customer.country)
      } catch (exception) {
        this.alert_message = this.$t(
          'apps.core.base_alert.dataloading_error_message'
        )
        this.alert_color = 'error'
        this.showSnackbar = true
      }
    },

    countryDropdownChange(value) {
      // On Country change update respective Country code, Currency and Languate fields
      if (value) {
        var countryData = this.countriesData.filter(
          (item) => item.country === value
        )
        try {
          this.currencies = countryData[0].currencies.map(
            (currency) => currency.currency
          )
          this.languages = countryData[0].languages.map(
            (language) => language.language
          )
          this.customer.country_code = countryData[0].countryCode
          
          this.customer.currency = this.currencies[0]
          this.customer.language = this.languages[0]
          this.disablefield = false
        } catch (exception) {
          this.alert_message = this.$t(
            'apps.core.base_alert.dataloading_error_message'
          )
          this.alert_color = 'error'
          this.showSnackbar = true
        }
      } else {
        this.currencies = []
        this.customer.country_code = ''
        this.languages = []
        this.disablefield = true
      }
    },

    async submit() {
        try {
          const profile = await webClientAuth.getProfile()

          const record = Object.assign({}, this.customer)
          // ** Convert date to Datetime ****
          const bDate = moment()
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          const eDate = moment()
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')

          // Generate JSON array format for multiple JDE ids
          record.jde_id = this.customer.jde_id.map((item) => {
            return {
              jde_id: item.jde_id,
              is_primary: item.is_primary,
            }
          })

          if (this.customer.is_active === true && this.is_active === false) {
            record.eff_end_date = moment
              .tz(String(eDate), 'YYYY-MM-DD HH:mm:ss', 'UTC')
              .format()
            record.is_active = this.is_active
          } else if (
            this.customer.is_active !== true &&
            this.is_active === true
          ) {
            record.eff_end_date = null
            record.eff_begin_date = moment
              .tz(String(bDate), 'YYYY-MM-DD HH:mm:ss', 'UTC')
              .format()
            record.is_active = this.is_active
          }

          let loaderMsg
          if (this.isEdit) {
            loaderMsg = this.$t(
              'apps.create_customer.messages.loader_updating_customer'
            )
            record.updated_by = profile.preferred_username
          } else {
            loaderMsg = this.$t(
              'apps.create_customer.messages.loader_complete_registration'
            )
            record.created_by = profile.preferred_username
          }
          this.$refs.loader.show(loaderMsg)
          // createCustomer() will handle both Create and Edit Customer details
          await customerMgmtStoreHelper.createCustomer(record)

          this.onCancelForm(true)
        } catch (exception) {
          this.alert_message = this.$t(
            'apps.core.base_alert.submit_error_message'
          )
          if (exception.message.includes('400')) {
            this.alert_message = this.$t(
              'apps.core.base_alert.jde_already_exists'
            )
          }
          this.alert_color = 'error'
          this.showSnackbar = true
          this.$refs.loader.hide()
        } finally {
          this.$refs.loader.hide()
        }
      // }
    },
    is_active_Changed() {
      if (
        this.isEdit &&
        this.customer.is_active === false &&
        this.is_active === true
      ) {
        this.alert_message = this.$t(
          'apps.core.base_alert.activate_inactive_customer'
        )
        this.alert_color = 'success'
        this.showSnackbar = true
      }
    },
    onCancelForm(isSuccess) {
      this.$emit('closeCustomerDailog', {
        isEdit: this.isEdit,
        isSuccess: isSuccess,
      })
    },
    closeJDEDailog($Payload) {
      this.JDEdialog = $Payload
    },
    addJdeId() {
      this.JDEdialog = true
    },
    saveJDE($Payload ) {
      this.customer.jde_id = $Payload
    },
  },


}
</script>

<style>
/* paragraph margin */
.v-application p {
  margin-bottom: -20px;
}
.spacing {
  margin-top: -10px;
}
.margin10 {
  margin-top: 10px;
}
.subheader {
  padding-right: 15px;
  padding-left: 15px;
}

.layoutmargin {
  margin: 0 !important;
}
.formComponentMargin {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}
.form_buttons {
  height: 35px;
  min-height: 35px !important;
}
.error_label {
  color: red;
  margin-bottom: 0px;
  line-height: 0px;
}
.newcustomerbutton {
  height: 2.75em !important;
  min-height: 2.75em !important;
}

/* Datepicker selector */
.v-btn:not(.v-btn--icon):not(.v-btn--floating) {
  min-height: 0em !important;
  min-width: 0em !important;
}
.addjdeid {
  position: relative;
  right: 10px;
  top: 30px;
}
.is_active {
  position: relative;
  padding-left: 10px;
  margin-top: 0px;
}
.boldtext {
  font-weight: bold;
}
.v-text-field--outlined .v-label {
  top: 10px !important;
}

.v-input__slot {
  margin-bottom: 2px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 3px !important;
}

.Jdebtn {
  padding: 50px 10px 10px 30px !important;
}
/* text-fields height */
.v-text-field:not(.no_border) > .v-input__control > .v-input__slot {
  min-height: 40px;
}

/* DropDown box height */
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  min-height: 40px !important;
  height: 40px !important;
}

/* Textarea height */
.v-textarea.v-text-field--box .v-text-field__prefix,
.v-textarea.v-text-field--box textarea,
.v-textarea.v-text-field--enclosed .v-text-field__prefix,
.v-textarea.v-text-field--enclosed textarea {
  height: 80px;
}

/* dropdown label color after selection */
.v-text-field .v-label--active {
  color: #003399 !important;
}

.text-danger {
  position: relative;
  color: #ff5252;
  bottom: 22px;
  font-size: 11px;
}

.v-icon .notranslate .material-icons .theme--light .primary--text {
  margin-bottom: 15px;
}
</style>
