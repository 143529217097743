export default {
  name: 'DataModelValidator',
  data: () => ({
    validationRules: {
      emrEndPoint: 'required|max:50',
      hl7AcceptAck: 'required|max:50',
      hl7AppAck: 'required|max:50',
      hl7RecApp: 'required|max:50',
      hl7RecFacility: 'required|max:50',
      hl7SendFacility: 'required|max:50',
    },
  }),
}
