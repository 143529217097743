import 'babel-polyfill'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import vuetify from '@plugins/vuetify'
import { application, bootstrap } from '@baxter/web-vueapp-lib'
import pluginInstaller from '@plugins/pluginInstaller'
import navigations from '@plugins/navigations'
import AppToolbarLogo from './plugins/customer-management/assets/images/AppToolbarLogoBase64'
import axios from 'axios'
import webClientAuth from '@baxter/web-client-auth'

bootstrap(Vue, navigations)
  .then(({ store, router, i18n }) => {
    pluginInstaller({ store: store, router: router, i18n: i18n })
    jwtInterceptor()
    /** Create the Vue Instance and setup the router, store, etc.. */
    new Vue({
      vuetify,
      router,
      store,
      i18n,
      beforeCreate() {
        this.$store.commit('initialiseStore')
        this.$appStoreHelper.setCustomOptions({
          toolbarLogo: {
            image: AppToolbarLogo.img,
            style: { width: '130px', 'margin-right': '10px', height: '30px' },
          },
        })
      },
      render: (h) => h(application),
    }).$mount('#app')
  })
  .catch((error) => {
    console.log('Bootstraping vuejs app failed.')
    console.log(error)
  })

export function jwtInterceptor() {
  axios.interceptors.request.use((request) => {
    // add auth header with jwt if account is logged in and request is to the api url
    const temp = JSON.parse(webClientAuth.getToken()).access_token

    if (temp) {
      request.headers.common.Authorization = temp
    }

    return request
  })
}
