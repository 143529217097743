<!-- eslint-disable -->
<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col class="xs" cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row justify="start" class="layoutmargin">
            <v-col class="xs" cols="1" sm="1" md="1" lg="1" xl="1">
              <v-btn id="backbutton" small @click.stop="$emit('hideDevices')">{{
                $t('apps.customer_management.device.back_button')
              }}</v-btn>
            </v-col>
            <v-col class="xs" cols="8" sm="8" md="8" lg="8" xl="8">
              <span class="headline"
                >{{ customerName }}:
                {{ $t('apps.customer_management.device.device_details') }}</span
              >
            </v-col>
            <v-col class="xs" cols="3" sm="3" md="3" lg="3" xl="3">
              <v-text-field
                id="searchtext"
                v-model="searchText"
                solo
                class="elevation-0"
                clearable
                clear-icon="cancel"
                :placeholder="
                  $t('apps.customer_management.customer.filters.search')
                "
                hide-details
                append-icon="search"
                @keyup.enter="
                  gridConfig.options.page = 1
                  refreshGrid()
                "
                @click:append="
                  gridConfig.options.page = 1
                  refreshGrid()
                "
                @click:clear="
                  searchText = ''
                  gridConfig.options.page = 1
                  refreshGrid()
                "
              ></v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="mx-4 pr-12 xs xs"
          cols="12"
          sm="12"
          md="12"
          lg="12"
          xl="12"
        >
          <WebDataGrid
            id="devicelist"
            :grid-config="gridConfig"
            :selected-rows.sync="selectedRows"
            @refresh-grid-event="refreshGrid"
            @pagination-next-click-event="refreshGrid"
            @pagination-previous-click-event="refreshGrid"
          >
            <template v-slot:item.iot_certificate_status="{ item }">
              {{
                item.iot_certificate_status === 'active'
                  ? $t('apps.customer_management.device.columns.active')
                  : $t('apps.customer_management.device.columns.inactive')
              }}
            </template>
          </WebDataGrid>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="xs" cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row justify="start" class="layoutmargin">
            <v-col class="xs" cols="12" sm="12" md="12" lg="12" xl="12">
              <span class="small-headline">{{
                $t('apps.customer_management.device.selected_devices')
              }}</span>
            </v-col>
          </v-row>
          <v-row justify="start" class="layoutmargin">
            <v-col class="xs" cols="11" sm="11" md="11" lg="11" xl="11">
              <v-textarea
                id="selecteddevices"
                :value="selectedRowsAccrossPages"
                solo
                hide-details
                auto-grow
                :placeholder="
                  $t('apps.customer_management.device.no_selected_devices')
                "
                readonly
              ></v-textarea>
            </v-col>
            <v-col class="xs" cols="1" sm="1" md="1" lg="1" xl="1">
              <v-btn
                id="edibutton"
                :disabled="!selectedRowsAccrossPages"
                small
                @click="editDevice"
                >{{ $t('apps.customer_management.device.edit_button') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-dialog
        v-if="editDeviceDailog"
        id="devicesettings"
        v-model="editDeviceDailog"
        width="900"
      >
        <editDevice
          :selected-devices="selectedRows"
          :customer-id="customerId"
          :edit-device-dailog.sync="editDeviceDailog"
          @closeEditDeviceDailog="closeEditDeviceDailog"
        ></editDevice>
      </v-dialog>

      <!-- Alert message -->
      <v-snackbar
        v-model="showSnackbar"
        top="top"
        absolute="true"
        :color="alertColor"
        :timeout="messageTimeout"
      >
        {{ alertMessage }}
        <v-btn color="black" text @click="showSnackbar = false">
          {{ $t('apps.core.base_alert.ok_button') }}
        </v-btn>
      </v-snackbar>
    </v-col>
  </v-row>
</template>
<script>
/* eslint-disable */ 
import { customerMgmtStoreHelper } from '@plugins/customer-management/state/helpers'
import { decodeException } from '@baxter/web-utils'
import customerConstants from '@plugins/customer-management/constants/const'
import editDevice from '@plugins/customer-management/components/editDevice'

export default {
  components: { editDevice },
  props: {
    customerId: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    messageTimeout: customerConstants.messageTimeout,
    alertMessage: null,
    showSnackbar: false,
    alertColor: 'success',
    editDeviceDailog: false,
    searchText: '',
    showSettings: false,
    selectedRows: [],
    validRecords: [],
    gridConfig: {
      records: [],
      loading: true,
      itemKey: customerConstants.devices.fields.therapy_device_id,
      headers: [],
      highlightKey: customerConstants.devices.fields.therapy_device_id,
      showSelect: true,
      selectedRows: [],
      preSelectedRows: [],
      options: {
        itemsPerPageOptions: customerConstants.numberOfRecordsPerPage,
        totalPages: 0,
        itemsPerPage: customerConstants.numberOfRecordsPerPage[0],
        page: 1,
        sortBy: 'id',
        descending: false,
      },
    },
  }),
  computed: {
    selectedRowsAccrossPages() {
      return this.selectedRows.map((x) => x.therapy_device_id).join(', ')
    },
  },
  watch: {
    selectedRows: function(val) {
      // ***  To Maintain selected Checkbox State on Pagination ***
      this.gridConfig.preSelectedRows = val
    },
  },

  methods: {
    getHeaders: function() {
      return [
        {
          text: this.$t('apps.customer_management.device.columns.device'),
          value: customerConstants.devices.fields.therapy_device_id,
          sortable: false,
        },
        {
          text: this.$t('apps.customer_management.device.columns.dcm_id'),
          value: customerConstants.devices.fields.gateway_serial_number,
          sortable: false,
        },
        {
          text: this.$t('apps.customer_management.device.columns.status'),
          value: customerConstants.devices.fields.iot_certificate_status,
          sortable: false,
        },
      ]
    },
    async refreshGrid() {
      try {
        const pageSize = this.gridConfig.options.itemsPerPage
        const pageNumber = this.gridConfig.options.page

        const request = {
          pagination:
            customerConstants.querystring.page +
            '~' +
            pageNumber +
            ',' +
            customerConstants.querystring.pagesize +
            '~' +
            pageSize,
          filter:
            customerConstants.devices.fields.customer_id +
            '~' +
            encodeURIComponent(this.customerId),
          fields:
            customerConstants.devices.fields.therapy_device_id +
            ',' +
            customerConstants.devices.fields.gateway_serial_number +
            ',' +
            customerConstants.devices.fields.iot_certificate_status,
        }

        if (
          this.searchText.includes('"') ||
          this.searchText.includes('\\') ||
          this.searchText.includes("\\'") ||
          this.searchText.includes('%')
        ) {
          throw 'not allowed characters entered in Search'
        }

        if (this.searchText !== null && this.searchText !== '') {
          request.search = encodeURIComponent(this.searchText)
        }
        // make a call
        // data loading started
        this.gridConfig.loading = true

        const response = await customerMgmtStoreHelper.getDevices(request)
        this.gridConfig.records = response.devices
        this.gridConfig.headers = this.getHeaders()
        this.gridConfig.options.totalItems = parseInt(response.totalRecords)
        this.gridConfig.options.totalPages = Math.ceil(
          this.gridConfig.options.totalItems / pageSize
        )
      } catch (exception) {
        this.gridConfig.loading = false
        this.alertMessage = this.$t(
          'apps.core.base_alert.dataloading_error_message'
        )
        if (exception.includes('not allowed characters entered in Search')) {
          this.alertMessage = this.$t(
            'apps.core.base_alert.search_character_not_allowed'
          )
        }
        this.alertColor = 'error'
        this.showSnackbar = true
      }

      // data api call is finished here, turn off the progress bar
      this.gridConfig.loading = false
    },
    closeEditDeviceDailog() {
      this.editDeviceDailog = false
      this.alertMessage = this.$t(
        'apps.edit_device.messages.edit_device_setting_success'
      )
      this.alertColor = 'success'
      this.showSnackbar = true
      this.refreshGrid()
      this.selectedRows = []
      this.validRecords = []
    },
    editDevice() {
      // Filter the devices which have active DCM
      this.validRecords = this.selectedRows.filter(
        (device) =>
          device.gateway_serial_number &&
          device.iot_certificate_status ===
            customerConstants.devices.fields.iot_certificate_status_value
      )

      // If user select only one inactive DCM then show Settings screen in disable state
      if (
        this.selectedRows.length === 1 &&
        this.selectedRows[0].gateway_serial_number &&
        this.selectedRows[0].iot_certificate_status !=
          customerConstants.devices.fields.iot_certificate_status_value
      ) {
        this.editDeviceDailog = true
      }
      // If there is no valid selected device then show error to user
      else if (this.validRecords.length === 0) {
        this.alertMessage = this.$t(
          'apps.edit_device.error_messages.edit_settings_error'
        )
        this.alertColor = 'error'
        this.showSnackbar = true
      }
      // If selected devices have ateleast one active DCM then show settings
      else {
        this.editDeviceDailog = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.background {
  background-color: rgba(84, 88, 90, 0.1) !important;
  vertical-align: middle !important;
  min-height: 60px !important;
  margin: auto !important;
  padding: 10px !important;
}
.layoutmargin {
  margin: 0 !important;
}
.formComponentMargin {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}
.form_buttons {
  height: 35px;
  min-height: 35px !important;
}
.error_label {
  color: red;
  margin-bottom: 0px;
  line-height: 0px;
}
.cancelbutton {
  height: 2.75em !important;
  min-height: 2.75em !important;
}
.background_Color {
  background-color: red;
}
//search box
.v-text-field.v-text-field--solo .v-input__control {
  min-height: 40px !important;
}

//DropDown label color
.v-text-field--outlined .v-label--active {
  color: #003399 !important;
}

.small-headline {
  font-size: 1.1rem !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: 'Roboto', sans-serif !important;
}

//Select Column checkbox gap
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 3px;
}
</style>
