<template>
  <WebPageContentLayout ref="contentLayout">
    <template v-slot:page-title>
      <v-row>
        <v-col>
          <span>{{ $t('apps.customer_management.page_title') }}</span>
        </v-col>
      </v-row>
    </template>

    <template v-slot:page-content>
      <v-row no-gutters>
        <v-col>
          <WebTabs>
            <WebTab to="/customer_management/customer">
              {{ $t('apps.customer_management.customer.tab_title') }}
            </WebTab>
          </WebTabs>
          <router-view></router-view>
        </v-col>
      </v-row>
    </template>
  </WebPageContentLayout>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$t('apps.customer_management.page_title'),
    }
  },
  data() {
    return {
      active_tab: '1',
    }
  },
  computed: {
    alertMessage: {
      get: function() {
        return this.$appStoreHelper.alertMessage
      },
    },
  },
  watch: {
    alertMessage: function(message) {
      this.$refs.contentLayout.showAlert(message)
    },
  },
}
</script>
