export default {
  name: 'CustomerFormValidator',
  data: () => ({
    validationRules: {
      name: 'required',
      jde_id: 'required',
      begin_date: 'required',
      email: 'required|email',
      country: 'required',
      phone: 'numeric|max:10',
    },
  }),
}
