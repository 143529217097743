<!-- eslint-disable -->
<template>
  <div>
    <v-row>
      <v-col class="xs" cols="12" sm="12" md="12" lg="12" xl="12">
        <v-row justify="start" class="layoutmargin">
          <v-col class="xs" cols="12" sm="12" md="12" lg="12" xl="12">
            <span class="small-headline">{{
              $t('apps.customer_management.device.selected_devices')
            }}</span>
          </v-col>
        </v-row>
        <v-row justify="start" class="layoutmargin">
          <v-col class="xs" cols="11" sm="11" md="11" lg="11" xl="11">
            <v-textarea
              id="selecteddevices"
              :value="selectedRowsAccrossPages"
              solo
              hide-details
              auto-grow
              :placeholder="
                $t('apps.customer_management.device.no_selected_devices')
              "
              readonly
            ></v-textarea>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-form ref="Editform" @submit.prevent="submit">
      <v-card>
        <v-container>
          <fieldset :disabled="isInactive">
            <v-card-title>
              <span class="headline">{{
                $t('apps.edit_device.edit_device_title')
              }}</span>
            </v-card-title>

            <v-card-text>
              <v-row class="xs6 md12 lg12 xl12" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="cloudTherapyDataPublishEnabled"
                    v-model="device.cloudTherapyDataPublishEnabled"
                    :label="
                      $t('apps.edit_device.cloud_Therapy_Data_Publish_Enabled')
                    "
                    :items="cloud_Therapy_Data_Publish_Enabled"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="hl7Connectivity"
                    v-model="device.hl7Connectivity"
                    :label="$t('apps.edit_device.hl7_Connectivity')"
                    :items="hl7_connectivity"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="emrEnabled"
                    v-model="device.emrEnabled"
                    :label="$t('apps.edit_device.emr_Enabled')"
                    :items="emr_Enabled"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="hl7AcceptAck"
                    v-model="device.hl7AcceptAck"
                    :label="$t('apps.edit_device.hl7_AcceptAck')"
                    :items="hl7_AcceptAck"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="emrDataFormat"
                    v-model="device.emrDataFormat"
                    :label="$t('apps.edit_device.emr_DataFormat')"
                    :items="emr_DataFormat"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="hl7AppAck"
                    v-model="device.hl7AppAck"
                    :label="$t('apps.edit_device.hl7AppAck')"
                    :items="hl7AppAck"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                    id="emrEndPoint"
                    v-model="device.emrEndPoint"
                    :label="$t('apps.edit_device.emr_endpoint')"
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                    id="hl7RecApp"
                    v-model="device.hl7RecApp"
                    :label="$t('apps.edit_device.hl7_rec_app')"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="emrUseThirdPartyCert"
                    v-model="device.emrUseThirdPartyCert"
                    :label="$t('apps.edit_device.emr_third_party_cert')"
                    :items="emr_third_party_cert"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                    id="hl7RecFacility"
                    v-model="device.hl7RecFacility"
                    :label="$t('apps.edit_device.hl7_rec_facility')"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="emrTlsEnabled"
                    v-model="device.emrTlsEnabled"
                    :label="$t('apps.edit_device.emr_tls_enabled')"
                    :items="emr_tls_enabled"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-text-field
                    id="hl7SendFacility"
                    v-model="device.hl7SendFacility"
                    :label="$t('apps.edit_device.hl7_send_facility')"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="emrDataBufferingEnabled"
                    v-model="device.emrDataBufferingEnabled"
                    :label="$t('apps.edit_device.emr_data_buffering')"
                    :items="emr_data_buffering"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="serialFeatureEnable"
                    v-model="device.serialFeatureEnable"
                    :label="$t('apps.edit_device.serial_enable')"
                    :items="serial_enable"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete id="rvEnable" v-model="device.rvEnable"
                    :label="$t('apps.edit_device.rv_enable')" :items="rvEnable" outlined
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete id="rvMaxConcurrentSession" v-model="device.rvMaxConcurrentSession"
                    :label="$t('apps.edit_device.rv_max_concurrent_session')"
                    :items="rvMaxConcurrentSession" outlined  
                    ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row class="xs6 md12 lg12 xl12 mt-n2" justify="space-between">
                <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                  <v-autocomplete
                    id="logLevel"
                    v-model="device.logLevel"
                    :label="$t('apps.edit_device.log_level')"
                    :items="log_level"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </fieldset>
          <v-card-actions>
            <v-row class="xs6 md12 lg12" justify="center">
              <v-btn
                v-if="!isInactive"
                id="submit"
                type="submit"
                class="form_buttons"
                color="primary"
               
                >{{ $t('apps.edit_device.save_button') }}</v-btn
              >
              <v-btn
                id="cancel"
                class="form_buttons"
                color="primary"
                @click="$emit('update:editDeviceDailog', false)"
                >{{ $t('apps.edit_device.cancel_button') }}</v-btn
              >
            </v-row>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-form>

    <!-- Loading indicator -->
    <WebDataLoading ref="loader"></WebDataLoading>

    <v-snackbar
      v-model="showSnackbar"
      absolute="true"
      top="top"
      :color="alert_color"
      :timeout="messageTimeout"
    >
      {{ alert_message }}
      <v-btn color="black" text @click="snackbar = false">{{
        $t('apps.core.base_alert.ok_button')
      }}</v-btn>
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable */ 
import DataModelValidator from '@plugins/customer-management/validators/editDeviceModel'
import customerConstants from '../constants/const'
import { deviceConstants } from '@plugins/customer-management/constants/const'
import { customerMgmtStoreHelper } from '@plugins/customer-management/state/helpers'
import _ from 'lodash'

export default {
  components: {},
  mixins: [DataModelValidator],
  props: {
    selectedDevices: {
      type: [],
      required: true,
    },
    customerId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isInactive: false,
    validRecords: [],
    device: {
      cloudTherapyDataPublishEnabled: '',
      hl7Connectivity: '',
      emrEnabled: '',
      hl7AcceptAck: '',
      emrDataFormat: '',
      emrUseThirdPartyCert: '',
      emrTlsEnabled: '',
      emrDataBufferingEnabled: '',
      serialFeatureEnable: '',
      logLevel: '',
      hl7AppAck: '',
      hl7RecApp: '',
      hl7RecFacility: '',
      emrEndPoint: '',
      hl7SendFacility: '',
      rvEnable: '',
      rvMaxConcurrentSession: '',
    },
    cloud_Therapy_Data_Publish_Enabled:
      deviceConstants.editDevice.cloudTherapyDataPublishEnabled,
    emr_Enabled: deviceConstants.editDevice.emrEnabled,
    log_level: deviceConstants.editDevice.logLevel,
    emr_DataFormat: deviceConstants.editDevice.emrDataFormat,
    emr_third_party_cert: deviceConstants.editDevice.emrUseThirdPartyCert,
    emr_tls_enabled: deviceConstants.editDevice.emrTlsEnabled,
    emr_data_buffering: deviceConstants.editDevice.emrDataBufferingEnabled,
    hl7_connectivity: deviceConstants.editDevice.hl7Connectivity,
    hl7_AcceptAck: deviceConstants.editDevice.hl7AcceptAck,
    hl7AppAck: deviceConstants.editDevice.hl7AppAck,
    serial_enable: deviceConstants.editDevice.serialFeatureEnable,
    rvEnable: deviceConstants.editDevice.rvEnable,
    rvMaxConcurrentSession: deviceConstants.editDevice.rvMaxConcurrentSession,

    error_Messages: null,
    alert_message: null,
    showSnackbar: false,
    alert_color: 'success',
    messageTimeout: deviceConstants.messageTimeout,
  }),
  computed: {
    selectedRowsAccrossPages() {
      return this.validRecords.map((x) => x.therapy_device_id).join(', ')
    },
  },
  mounted() {
    this.error_Messages = {
      custom: {
        emrEndPoint: {
          required: this.$t('apps.edit_device.error_messages.emrEndPoint'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
        hl7AcceptAck: {
          required: this.$t('apps.edit_device.error_messages.hl7AcceptAck'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
        hl7ProcessingId: {
          required: this.$t('apps.edit_device.error_messages.hl7ProcessingId'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
        hl7RecApp: {
          required: this.$t('apps.edit_device.error_messages.hl7RecApp'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
        hl7RecFacility: {
          required: this.$t('apps.edit_device.error_messages.hl7RecFacility'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
        hl7SendFacility: {
          required: this.$t('apps.edit_device.error_messages.hl7SendFacility'),
          max: this.$t('apps.edit_device.error_messages.Max'),
        },
      },
    }
  },
  async created() {
    this.validRecords = this.selectedDevices
    this.getDeviceSettings()
  },
  methods: {
    setDefaultValue() {
      ;(this.device.cloudTherapyDataPublishEnabled = 'False'),
        (this.device.hl7Connectivity = 'False'),
        (this.device.emrEnabled = 'False'),
        (this.device.hl7AcceptAck = 'AL'),
        (this.device.emrDataFormat = 'Prismax_V2'),
        (this.device.emrUseThirdPartyCert = 'False'),
        (this.device.emrTlsEnabled = 'False'),
        (this.device.emrDataBufferingEnabled = 'True'),
        (this.device.serialFeatureEnable = 'True'),
        (this.device.logLevel = 'Debug'),
        (this.device.hl7AppAck = 'AL'),
        (this.device.hl7RecApp = ''),
        (this.device.hl7RecFacility = ''),
        (this.device.emrEndPoint = ''),
        (this.device.hl7SendFacility = ''),
        (this.device.rvEnable = 'False'),
        (this.device.rvMaxConcurrentSession = '1')
    },
    async getDeviceSettings() {
      try {
        // If only one device is selected then call API to get DCM settings
        if (this.validRecords.length === 1) {
          if (
            this.validRecords[0].iot_certificate_status !=
            customerConstants.devices.fields.iot_certificate_status_value
          ) {
            this.isInactive = true
          }

          const { therapy_device_id, gateway_serial_number } = _.head(
            this.validRecords
          )
          const response = await customerMgmtStoreHelper.getSelectedDevices({
            therapy_device_id,
            gateway_serial_number,
          })
          const data = response.state.reported.config
          const mergedObj = Object.assign({}, this.device, data)
          this.device = mergedObj
          this.formatDeviceSettings()
        } else {
          this.validRecords = this.validRecords.filter(
            (device) =>
              device.gateway_serial_number &&
              device.iot_certificate_status ===
                customerConstants.devices.fields.iot_certificate_status_value
          )
          // If valid records are less than selected records then show warning message to user.
          if (this.validRecords.length < this.selectedDevices.length) {
            this.alert_message = this.$t(
              'apps.edit_device.error_messages.edit_settings_warning'
            )
            this.alert_color = 'error'
            this.showSnackbar = true
          }

          this.setDefaultValue()
        }
      } catch (exception) {
        this.alert_message = this.$t(
          'apps.core.base_alert.dataloading_error_message'
        )
        this.alert_color = 'error'
        this.showSnackbar = true
      }
    },
    formatDeviceSettings() {
      const fieldArray = [
        'cloudTherapyDataPublishEnabled',
        'emrEnabled',
        'emrUseThirdPartyCert',
        'emrTlsEnabled',
        'emrDataBufferingEnabled',
        'hl7Connectivity',
        'serialFeatureEnable',
        'rvEnable'
      ]
      fieldArray.forEach((element) => {
        if (
          this.device[element].toUpperCase() ===
          deviceConstants.editDevice.boolen_true_capital
        ) {
          this.device[element] = deviceConstants.editDevice.boolen_true
        } else {
          this.device[element] = deviceConstants.editDevice.boolen_false
        }
      })

      // EMR Data format
      const emrDataFormatIndex = deviceConstants.editDevice.emrDataFormat_capital.indexOf(
        this.device.emrDataFormat.toUpperCase()
      )
      if (emrDataFormatIndex >= 0) {
        this.device.emrDataFormat =
          deviceConstants.editDevice.emrDataFormat[emrDataFormatIndex]
      }

      // Log level
      const logLevelIndex = deviceConstants.editDevice.logLevel_capital.indexOf(
        this.device.logLevel.toUpperCase()
      )

      if (logLevelIndex >= 0) {
        this.device.logLevel =
          deviceConstants.editDevice.logLevel[logLevelIndex]
      }

      // hl7AcceptAck hl7AppAck
      this.device.hl7AcceptAck = this.device.hl7AcceptAck.toUpperCase()
      this.device.hl7AppAck = this.device.hl7AppAck.toUpperCase()
    },
    async submit() {
      try {
        const data = new Object()
        data.customerId = this.customerId
        data.settings = Object.assign({}, this.device)
        data.things = this.validRecords.map((x) => x.gateway_serial_number)

        const loaderMsg = this.$t(
          'apps.edit_device.messages.loader_updating_settings'
        )
        this.$refs.loader.show(loaderMsg)
        await customerMgmtStoreHelper.postSelectedDevices(data)
        this.$emit('closeEditDeviceDailog')
      } catch (exception) {
        this.alert_message = this.$t(
          'apps.core.base_alert.submit_error_message'
        )
        this.alert_color = 'error'
        this.showSnackbar = true
      } finally {
        this.$refs.loader.hide()
      }
    },
  },
}
</script>

<style>
/* paragraph margin */
.v-application p {
  margin-bottom: -20px;
}
.spacing {
  margin-top: -10px;
}
.margin10 {
  margin-top: 10px;
}
.subheader {
  padding-right: 15px;
  padding-left: 15px;
}

.layoutmargin {
  margin: 0 !important;
}
.formComponentMargin {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}
.form_buttons {
  height: 35px;
  min-height: 35px !important;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.newcustomerbutton {
  height: 2.75em !important;
  min-height: 2.75em !important;
}

/* Datepicker selector */
.v-btn:not(.v-btn--icon):not(.v-btn--floating) {
  min-height: 0em !important;
  min-width: 0em !important;
}

.v-text-field--outlined .v-label {
  top: 10px !important;
}

.v-input__slot {
  margin-bottom: 2px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 3px !important;
}

/* text-fields height */
.v-text-field:not(.no_border) > .v-input__control > .v-input__slot {
  min-height: 40px;
}

/* DropDown box height */
.v-autocomplete.v-input > .v-input__control > .v-input__slot {
  min-height: 40px !important;
  height: 40px !important;
}

/* dropdown label color after selection */
.v-text-field .v-label--active {
  color: #003399 !important;
}
</style>

<style lang="scss" scoped>
::v-deep .v-icon.v-icon {
  margin-bottom: 15px;
}

::v-deep .v-btn__content {
  width: 50px;
}
</style>
