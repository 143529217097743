var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-row',[_c('v-row',{staticClass:"layoutmargin",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"xs ml-3",attrs:{"cols":"6","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('v-combobox',{attrs:{"id":"country","items":_vm.countries,"label":_vm.$t(
                'apps.customer_management.customer.filters.country_filter_label'
              ),"outlined":"","dense":"","item-text":"country","item-value":"country","return-object":false},on:{"change":_vm.filter_Changed},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1),_c('v-col',{staticClass:"xs",attrs:{"cols":"6","sm":"6","md":"4","lg":"4","xl":"4"}},[_c('v-btn',{staticClass:"newcustomerbutton",attrs:{"id":"newcustomerbutton"},on:{"click":_vm.createCustomer}},[_vm._v(" "+_vm._s(_vm.$t('apps.customer_management.customer.register_new_customer'))+" ")])],1)],1),_c('v-row',{staticClass:"layoutmargin",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"xs",attrs:{"cols":"6","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{staticClass:"elevation-0",attrs:{"id":"searchtext","solo":"","clearable":"","clear-icon":"cancel","placeholder":_vm.$t('apps.customer_management.customer.filters.search'),"hide-details":"","append-icon":"search"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.gridConfig.options.page = 1
              _vm.refreshGrid()},"click:append":function($event){_vm.gridConfig.options.page = 1
              _vm.refreshGrid()},"click:clear":function($event){_vm.searchText = ''
              _vm.gridConfig.options.page = 1
              _vm.refreshGrid()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('v-col',{staticClass:"xs mr-3",attrs:{"cols":"6","sm":"6","md":"6","lg":"5","xl":"5"}},[_c('v-combobox',{attrs:{"id":"additionalcolumn","items":_vm.nonMandatoryColumList,"label":_vm.$t(
                'apps.customer_management.customer.filters.column_dropdown_label'
              ),"multiple":"","outlined":"","dense":""},on:{"change":_vm.column_Changed},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
              var item = ref.item;
              var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"subtitle-2"},[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"caption"},[_vm._v("(+"+_vm._s(_vm.selectedColumns.length - 1)+" others)")]):_vm._e()]}}]),model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-4 pr-12 xs xs",attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('WebDataGrid',{attrs:{"id":"customerlist","grid-config":_vm.gridConfig},on:{"refresh-grid-event":_vm.refreshGrid,"pagination-next-click-event":_vm.refreshGrid,"pagination-previous-click-event":_vm.refreshGrid},scopedSlots:_vm._u([{key:"item.jde_id",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.normalizeJDEId(item.jde_id)))]}},{key:"item.edit",fn:function(ref){
              var item = ref.item;
return [_c('WebLinkButton',{ref:"editButton",staticClass:"editButton",attrs:{"id":"edit"},on:{"click":function($event){return _vm.editCustomer(item)}}},[_vm._v(" "+_vm._s(_vm.$t('apps.customer_management.customer.columns.edit'))+" ")])]}},{key:"item.device",fn:function(ref){
              var item = ref.item;
return [_c('WebLinkButton',{attrs:{"id":"showdevices"},on:{"click":function($event){return _vm.showDevices(item)}}},[_vm._v(" "+_vm._s(_vm.$t('apps.customer_management.customer.columns.devices'))+" ")])]}},{key:"item.is_active",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_active ? _vm.$t('apps.customer_management.customer.columns.active') : _vm.$t('apps.customer_management.customer.columns.inactive'))+" ")]}},{key:"item.create_ts",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.normalizeDate(item.create_ts)))]}},{key:"item.update_ts",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.normalizeDate(item.update_ts)))]}},{key:"item.eff_begin_date",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.normalizeDate(item.eff_begin_date)))]}},{key:"item.eff_end_date",fn:function(ref){
              var item = ref.item;
return [_vm._v(_vm._s(_vm.normalizeDate(item.eff_end_date)))]}}])})],1)],1)],1),(_vm.dialog)?_c('v-dialog',{attrs:{"id":"createcustomer","width":700,"height":700},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('CreateNewCustomer',{attrs:{"customer":_vm.customer,"is-edit":_vm.isEdit},on:{"closeCustomerDailog":function($event){return _vm.closeCustomerDailog($event)}}})],1):_vm._e(),(_vm.devicesDialog)?_c('v-dialog',{attrs:{"id":"devicedialog","width":"1200"},model:{value:(_vm.devicesDialog),callback:function ($$v) {_vm.devicesDialog=$$v},expression:"devicesDialog"}},[_c('devices',{key:_vm.deviceDialogCustomer.id,attrs:{"customer-name":_vm.deviceDialogCustomer.name,"customer-id":_vm.deviceDialogCustomer.id},on:{"hideDevices":_vm.hideDevices}})],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"top","color":_vm.alertColor,"timeout":_vm.messageTimeout},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" "),_c('v-btn',{attrs:{"color":"black","text":""},on:{"click":function($event){_vm.showSnackbar = false}}},[_vm._v(_vm._s(_vm.$t('apps.core.base_alert.ok_button')))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }