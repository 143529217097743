const navigations = [
  {
    api: 'app/addNavigationToDrawer',
    value: {
      groupId: 1,
      item: {
        display: 'apps.customer_management.nav_link.title',
        routeLink: '/customer_management/customer',
        avatar: require('@plugins/customer-management/assets/images/customer.png'),
        position: 0,
      },
    },
  },
  {
    api: 'app/addLinkToHomePage',
    value: {
      position: 0,
      display: 'apps.customer_management.home_link.title',
      routeLink: '/customer_management/customer',
      avatar: require('@plugins/customer-management/assets/images/customer.png'),
      description: 'apps.customer_management.home_link.description',
      link: 'apps.customer_management.home_link.link',
    },
  },
]

export default navigations
