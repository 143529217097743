import { render, staticRenderFns } from "./index.vue?vue&type=template&id=86951962&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../.yarn/__virtual__/vue-loader-virtual-e4a21ae091/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../.yarn/__virtual__/vuetify-loader-virtual-af7728eaca/0/cache/vuetify-loader-npm-1.4.3-e983bc448f-837369063b.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
