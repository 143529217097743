import Vue from 'vue'
import WebDataGrid from '@baxter/web-data-grid'
import WebDataExport from '@baxter/web-data-export'
import WebVueAppLib from '@baxter/web-vueapp-lib'

import CustomerMgmtPlugin from '@plugins/customer-management'

export default function(options) {
  Vue.use(WebVueAppLib, options)
  Vue.use(WebDataGrid, options)
  Vue.use(WebDataExport, options)

  // install domain plugin
  Vue.use(CustomerMgmtPlugin, options)
}
