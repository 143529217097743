<!-- eslint-disable -->
<template>
  <div>
    <v-form>
      <v-card>
        <v-card-title>
          <!-- Header of Dialog -->
          <span class="headline">
            {{ $t('apps.create_customer.multipleJDE.title') }}
          </span>
        </v-card-title>

        <!-- Header of list -->
        <v-card-text>
          <v-row justify="space-between">
            <v-col cols sm="5" md="5" lg="5" xl="5">
              <span class="ml-6">
                {{ $t('apps.create_customer.multipleJDE.primary') }}
              </span>
              <span class="ml-3">
                {{ $t('apps.create_customer.multipleJDE.jdeid') }}
              </span>
            </v-col>
          </v-row>
          <!-- Show list of components to Add/Edit multiple JDEids -->
          <v-radio-group v-model="primaryChecked" :mandatory="true">
            <v-row
              v-for="(item, index) in jdeIdsList"
              :key="index"
              class="xs6 md12 lg12 xl12"
              justify="space-between"
            >
              <v-col cols="2" sm="2" md="2" lg="2" xl="2" class="pl-12 mt-1">
                <v-radio
                  :id="'radio' + index"
                  name="primaryChecked"
                  color="indigo"
                  :value="item.jde_id"
                  @change="radioCheckChange"
                ></v-radio>
              </v-col>
              <v-col
                cols="6"
                sm="6"
                md="6"
                lg="6"
                xl="6"
                class="formComponentMargin"
              >
                <v-text-field
                  :id="'jdeid' + index"
                  v-model="item.jde_id"
                  name="JDE_ID"
                  outlined
                  :disabled="item.is_edit"
                  :maxlength="jdeId_maxLength"
                  @change.native="jdeTextChange($event, val)"
                  @keydown="validateJDEChars($event, index)"
                  @input="errorJde = {}"
                ></v-text-field>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="4"
                lg="4"
                xl="4"
                class="formComponentMargin margin10"
              >
                <span v-if="index === 0">
                  <v-btn
                    id="addJdeBtn"
                    outlined
                    color="success"
                    @click="addJdE"
                    >{{ $t('apps.create_customer.multipleJDE.add') }}</v-btn
                  >
                </span>
                <span v-else>
                  <v-btn
                    v-show="item.is_edit === false"
                    id="deleteJdeBtn"
                    outlined
                    color="error"
                    @click="deleteJde(index)"
                    >{{ $t('apps.create_customer.multipleJDE.delete') }}</v-btn
                  >
                </span>
              </v-col>
            </v-row>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <p
            v-for="err in Object.values(errorJde)"
            :key="err"
            class="error_label"
            >{{ err }}</p
          >
        </v-card-actions>

        <!-- Action buttons -->
        <v-card-actions>
          <v-row>
            <v-col class="btnAlign">
              <v-btn
                id="savejde"
                class="form_buttons"
                outlined
                color="primary"
                @click="saveJde"
                >{{ $t('apps.create_customer.multipleJDE.submit') }}</v-btn
              >
              <v-btn
                id="canceljde"
                class="form_buttons"
                outlined
                color="primary"
                @click="cancelDailog"
                >{{ $t('apps.create_customer.multipleJDE.cancel') }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable */ 
import customerConstants from '../constants/const'
export default {
  props: {
    jdeIdsListProp: {
      type: Array,
    },
  },
  data: () => ({
    jdeIdsList: [],
    errorJde: {},
    maxJde: 10,
    primaryChecked: null,
    jdeId_maxLength: customerConstants.jdeIds.maxLength,
  }),
  created() {
    this.jdeIdsList = Object.assign([], this.jdeIdsListProp)

    if (this.jdeIdsList && this.jdeIdsList.length <= 0) {
      this.jdeIdsList.push({
        jde_id: null,
        is_primary: true,
        is_edit: false,
      })
      this.primaryChecked = null
    } else {
      this.jdeIdsList.filter((item, index) => {
        if (item.is_primary) {
          this.primaryChecked = item.jde_id
        }
      })
    }
  },
  methods: {
    addJdE() {
      // ****  Validation ****
      const isValid = this.validateJde()
      if (!isValid) {
        return
      }

      this.jdeIdsList.push({
        jde_id: '',
        is_primary: false,
        is_edit: false,
      })
    },
    deleteJde(index) {
      this.errorJde = {}
      this.jdeIdsList.splice(index, 1)
      this.$nextTick(() => {
        this.modelBindingJde()
      })
    },
    cancelDailog() {
      this.errorJde = {}
      this.$emit('closeJDEDailog', false)
    },

    saveJde() {
      if (this.saveValidateJde()) {
        this.$emit('saveJDE', this.jdeIdsList)
        this.cancelDailog()
      }
    },
    validateJde() {
      this.errorJde = {}
      const IsRequired = this.jdeIdsList.every((item, index) => {
        if (item.jde_id) return true
        else return false
      })

      if (!IsRequired) {
        this.errorJde = {
          required: this.$t('apps.create_customer.multipleJDE.messages.jde_id'),
        }
        return false
      }

      if (this.jdeIdsList.length >= this.maxJde) {
        this.errorJde = {
          Max: this.$t('apps.create_customer.multipleJDE.messages.max_jde_id', {
            max_jdeid: this.maxJde,
          }),
        }
        return false
      }

      return true
    },
    saveValidateJde() {
      this.errorJde = {}
      const IsRequired = this.jdeIdsList.find((item) => {
        if (!item.jde_id) return item
      })

      if (IsRequired) {
        this.errorJde = {
          required: this.$t('apps.create_customer.multipleJDE.messages.jde_id'),
        }
        return false
      }

      if (this.jdeIdsList.length <= this.maxJde) {
        return true
      } else {
        this.errorJde = {
          Max: this.$t('apps.create_customer.multipleJDE.messages.max_jde_id', {
            max_jdeid: this.maxJde,
          }),
        }
        return false
      }
    },

    modelBindingJde() {
      // ***  binding Primary to model  ***
      this.jdeIdsList.forEach((item, index) => {
        if (item.jde_id === this.primaryChecked) item.is_primary = true
        else item.is_primary = false
      })
    },

    validateJDEChars($event, index) {
      const value = event.target.value
      if (String(value).length === this.jdeId_maxLength) {
        this.errorJde = {
          Max: this.$t(
            'apps.create_customer.multipleJDE.messages.jde_id_max_length',
            {
              length: this.jdeId_maxLength,
            }
          ),
        }
      }
      this.$forceUpdate()
    },

    jdeTextChange($event, val) {
      this.errorJde = {}
      const duplicateJde = []
      const value = $event.target.value
      const jdeIds = Object.assign([], this.jdeIdsList)

      if (value) {
        jdeIds.forEach((item, index) => {
          if (item.jde_id === value) {
            duplicateJde.push(index)
          }
        })

        if (duplicateJde.length > 1) {
          const maxIndex = Math.max(...duplicateJde)
          this.jdeIdsList[maxIndex].jde_id = ''
          this.errorJde = {
            Unique: this.$t(
              'apps.create_customer.multipleJDE.messages.duplicate_jdeid'
            ),
          }
        }
      }
    },
    radioCheckChange() {
      this.$nextTick(() => {
        this.modelBindingJde()
      })
    },
  },
}
</script>

<style scoped lang="scss">
.deletebtn {
  height: 30px;
  width: 35px;
}

.form_buttons {
  position: relative;
  bottom: 25px;
}

.formComponentMargin-Radio {
  padding-top: 23px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}
.spacing {
  margin-top: -10px;
  margin-bottom: 21px;
}

.margin10 {
  margin-top: 4px;
}

.layoutmargin {
  margin: 0 !important;
}
.formComponentMargin {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 15px;
  padding-left: 15px;
}

.error_label {
  color: #ff5252;
  position: relative;
  bottom: 50px;
  font-size: 15px;
  margin-left: 100px;
}
.newcustomerbutton {
  height: 2.75em !important;
  min-height: 2.75em !important;
}

.v-text-field--filled > .v-input__control > .v-input__slot,
.v-text-field--full-width > .v-input__control > .v-input__slot,
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
  height: 40px !important;
}
.v-text-field--outlined .v-label {
  top: 10px !important;
}

.v-input__slot {
  margin-bottom: 2px !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 3px !important;
}

.Jdebtn {
  padding: 56px 0px 0px 37px !important;
}

.btnAlign {
  padding-left: 160px;
}

.erralign {
  padding-left: 50px;
}

.jdeText {
  margin-right: 200px;
}

.v-input--selection-controls.v-input {
  position: relative;
  bottom: 25px;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  width: 80px;
}
</style>
