const customerConstants = {
  numberOfRecordsPerPage: [10, 25, 50, 100],
  allTextInCountryDropdown: 'All',
  messageTimeout: 5000,
  jdeIds: {
    maxLength: 50,
  },
  fields: {
    id: 'id',
    name: 'name',
    address_line_1: 'address_line_1',
    address_line_2: 'address_line_2',
    city: 'city',
    state: 'state',
    zip: 'zip',
    country: 'country',
    country_code: 'country_code',
    currency: 'currency',
    language: 'language',
    jde_id: 'jde_id',
    email: 'email',
    phone: 'phone',
    url: 'url',
    is_active: 'is_active',
    eff_begin_date: 'eff_begin_date',
    eff_end_date: 'eff_end_date',
    created_by: 'created_by',
    create_ts: 'create_ts',
    updated_by: 'updated_by',
    update_ts: 'update_ts',
    edit: 'edit',
    device: 'device',
  },
  querystring: {
    page: 'page',
    pagesize: 'pageSize',
    filter: 'filter',
    search: 'search',
  },
  devices: {
    fields: {
      therapy_device_id: 'therapy_device_id',
      gateway_serial_number: 'gateway_serial_number',
      iot_certificate_status: 'iot_certificate_status',
      customer_id: 'customer_id',
      iot_certificate_status_value: 'active',
    },
  },
  countrylist: [
    {
      country: 'Australia',
      countryCode: 'AU',
      currencies: [{ currency: 'Australian Dollar', currencyCode: 'AUD' }],
      languages: [{ language: 'English US', languageCode: 'en-AU' }],
    },
    {
      country: 'Austria',
      countryCode: 'AT',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'German', languageCode: 'de-AT' }],
    },
    {
      country: 'Belgium',
      countryCode: 'BE',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [
        { language: 'Dutch', languageCode: 'nl-BE' },
        { language: 'French', languageCode: 'fr-BE' },
        { language: 'German', languageCode: '' },
      ],
    },
    {
      country: 'Brazil',
      countryCode: 'BR',
      currencies: [{ currency: 'Brazilian Real', currencyCode: 'BRL' }],
      languages: [
        { language: 'Brazilian', languageCode: 'pt-BR' },
        { language: 'Portuguese', languageCode: 'pt-PT' },
      ],
    },
    {
      country: 'Canada',
      countryCode: 'CA',
      currencies: [{ currency: 'Canadian Dollar', currencyCode: 'CAD' }],
      languages: [
        { language: 'English US', languageCode: 'en-CA' },
        { language: 'French', languageCode: 'fr-CA' },
      ],
    },
    {
      country: 'China',
      countryCode: 'CN',
      currencies: [
        { currency: 'Renminbi (Chinese) Yuan', currencyCode: 'CNY' },
      ],
      languages: [{ language: 'Traditional Chinese', languageCode: 'zh' }],
    },
    {
      country: 'Colombia',
      countryCode: 'CO',
      currencies: [{ currency: 'Colombian Peso', currencyCode: 'COP' }],
      languages: [{ language: 'Spanish', languageCode: 'es-CO' }],
    },
    {
      country: 'Croatia',
      countryCode: 'HR',
      currencies: [{ currency: 'Croatian Kuna', currencyCode: 'HRK' }],
      languages: [{ language: 'Croatian', languageCode: 'hr' }],
    },
    {
      country: 'Czech Republic',
      countryCode: 'CZ',
      currencies: [{ currency: 'Czech Koruna', currencyCode: 'CZK' }],
      languages: [{ language: 'Czech', languageCode: 'cs' }],
    },
    {
      country: 'Denmark',
      countryCode: 'DK',
      currencies: [{ currency: 'Danish Krone', currencyCode: 'DKK' }],
      languages: [{ language: 'Danish', languageCode: 'da-DK' }],
    },
    {
      country: 'Finland',
      countryCode: 'FI',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Finnish', languageCode: 'fi-FI' }],
    },
    {
      country: 'France',
      countryCode: 'FR',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'French', languageCode: 'fr-FR' }],
    },
    {
      country: 'Germany',
      countryCode: 'DE',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'German', languageCode: 'de-DE' }],
    },
    {
      country: 'Greece',
      countryCode: 'GR',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Greek', languageCode: 'el-GR' }],
    },
    {
      country: 'Hong Kong',
      countryCode: 'HK',
      currencies: [{ currency: 'Hong Kong Dollar', currencyCode: 'HKD' }],
      languages: [
        { language: 'English US', languageCode: '' },
        { language: 'Traditional Chinese', languageCode: 'zh-HK' },
      ],
    },
    {
      country: 'Hungary',
      countryCode: 'HU',
      currencies: [{ currency: 'Hungarian Forint', currencyCode: 'HUF' }],
      languages: [{ language: 'Hungarian', languageCode: 'hu' }],
    },
    {
      country: 'India',
      countryCode: 'IN',
      currencies: [{ currency: 'Indian Rupee', currencyCode: 'INR' }],
      languages: [{ language: 'Hindi', languageCode: 'hi' }],
    },
    {
      country: 'Ireland',
      countryCode: 'IE',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'English US', languageCode: 'en-IE' }],
    },
    {
      country: 'Israel',
      countryCode: 'IL',
      currencies: [{ currency: 'Israeli New Shekel', currencyCode: 'ILS' }],
      languages: [{ language: 'Hebrew', languageCode: 'he' }],
    },
    {
      country: 'Italy',
      countryCode: 'IT',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Italian', languageCode: 'it-IT' }],
    },
    {
      country: 'Kuwait',
      countryCode: 'KW',
      currencies: [{ currency: 'Kuwaiti Dinar', currencyCode: 'KWD' }],
      languages: [{ language: 'Arabic', languageCode: 'ar' }],
    },
    {
      country: 'Luxembourg',
      countryCode: 'LU',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'French', languageCode: 'de-LU' }],
    },
    {
      country: 'Malaysia',
      countryCode: 'MY',
      currencies: [{ currency: 'Malaysian Ringgit', currencyCode: 'MYR' }],
      languages: [{ language: 'Malay', languageCode: 'ms' }],
    },
    {
      country: 'Mexico',
      countryCode: 'MX',
      currencies: [{ currency: 'Mexican Peso', currencyCode: 'MXN' }],
      languages: [{ language: 'Spanish', languageCode: 'es-MX' }],
    },
    {
      country: 'Netherlands',
      countryCode: 'NL',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Dutch', languageCode: 'nl-NL' }],
    },
    {
      country: 'New Zealand',
      countryCode: 'NZ',
      currencies: [{ currency: 'New Zealand Dollar', currencyCode: 'NZD' }],
      languages: [{ language: 'English US', languageCode: 'en-NZ' }],
    },
    {
      country: 'Norway',
      countryCode: 'NO',
      currencies: [{ currency: 'Norwegian Krone', currencyCode: 'NOK' }],
      languages: [{ language: 'Norwegian', languageCode: 'nn-NO' }],
    },
    {
      country: 'Poland',
      countryCode: 'PL',
      currencies: [{ currency: 'Zloty', currencyCode: 'PLN' }],
      languages: [{ language: 'Polish', languageCode: 'pl-PL' }],
    },
    {
      country: 'Portugal',
      countryCode: 'PT',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Portuguese', languageCode: 'pt-PT' }],
    },
    {
      country: 'Romania',
      countryCode: 'RO',
      currencies: [{ currency: 'Romanian Leu', currencyCode: 'RON' }],
      languages: [{ language: 'Romanian', languageCode: 'ro' }],
    },
    {
      country: 'Russia',
      countryCode: 'RU',
      currencies: [{ currency: 'Russian Ruble', currencyCode: 'RUB' }],
      languages: [{ language: 'Russian', languageCode: 'ru-RU' }],
    },
    {
      country: 'Saudi Arabia',
      countryCode: 'SA',
      currencies: [{ currency: 'Saudi Riyal', currencyCode: 'SAR' }],
      languages: [{ language: 'Modern Standard Arabic', languageCode: 'ar' }],
    },
    {
      country: 'Singapore',
      countryCode: 'SG',
      currencies: [{ currency: 'Singapore Dollar', currencyCode: 'SGD' }],
      languages: [
        { language: 'English US', languageCode: '' },
        { language: 'Simplified Chinese', languageCode: 'zh-SG' },
      ],
    },
    {
      country: 'Slovenia',
      countryCode: 'SI',
      currencies: [{ currency: 'Slovenian Tolar', currencyCode: 'SIT' }],
      languages: [{ language: 'Slovenian', languageCode: 'sl' }],
    },
    {
      country: 'South Africa',
      countryCode: 'ZA',
      currencies: [
        { currency: 'South African Financial Rand', currencyCode: 'ZAL' },
      ],
      languages: [{ language: 'English US', languageCode: 'en-US' }],
    },
    {
      country: 'South Korea',
      countryCode: 'KOR',
      currencies: [{ currency: 'Won', currencyCode: 'KRW' }],
      languages: [{ language: 'Korean', languageCode: 'ko-KR' }],
    },
    {
      country: 'Spain',
      countryCode: 'ES',
      currencies: [{ currency: 'Euro', currencyCode: 'EUR' }],
      languages: [{ language: 'Spanish', languageCode: 'es-ES' }],
    },
    {
      country: 'Sweden',
      countryCode: 'SE',
      currencies: [{ currency: 'Swedish Krona', currencyCode: 'SEK' }],
      languages: [{ language: 'Swedish', languageCode: 'sv-SE' }],
    },
    {
      country: 'Switzerland',
      countryCode: 'CH',
      currencies: [
        { currency: 'Swiss Franc', currencyCode: 'CHF' },
        { currency: 'WIR Euro', currencyCode: 'CHE' },
        { currency: 'WIR Franc', currencyCode: 'CHW' },
      ],
      languages: [
        { language: 'French', languageCode: 'fr-CH' },
        { language: 'Italian', languageCode: 'it-CH' },
        { language: 'German', languageCode: 'de-CH' },
      ],
    },
    {
      country: 'Taiwan',
      countryCode: 'TW',
      currencies: [{ currency: 'New Taiwan Dollar', currencyCode: 'TWD' }],
      languages: [{ language: 'Traditional Chinese', languageCode: 'zh-TW' }],
    },
    {
      country: 'Thailand',
      countryCode: 'TH',
      currencies: [{ currency: 'Thai Baht', currencyCode: 'THB' }],
      languages: [{ language: 'Thai', languageCode: 'th' }],
    },
    {
      country: 'Turkey',
      countryCode: 'TR',
      currencies: [{ currency: 'Turkish Lira', currencyCode: 'TRY' }],
      languages: [{ language: 'Turkish', languageCode: 'tr-TR' }],
    },
    {
      country: 'UK',
      countryCode: 'GB',
      currencies: [{ currency: 'Pound Sterling', currencyCode: 'GBP' }],
      languages: [{ language: 'English US', languageCode: 'en-GB' }],
    },
    {
      country: 'United Arab Emirates',
      countryCode: 'AE',
      currencies: [
        { currency: 'United Arab Emirates Dirham', currencyCode: 'AED' },
      ],
      languages: [{ language: 'Modern Standard Arabic', languageCode: 'ae' }],
    },
    {
      country: 'USA',
      countryCode: 'US',
      currencies: [{ currency: 'US Dollar', currencyCode: 'USD' }],
      languages: [{ language: 'English US', languageCode: 'en-US' }],
    },
    {
      country: 'Vietnam',
      countryCode: 'VN',
      currencies: [{ currency: 'Vietnamese Dong', currencyCode: 'VND' }],
      languages: [{ language: 'Vietnamese', languageCode: 'vi' }],
    },
  ],
}

export default customerConstants

export const deviceConstants = {
  editDevice: {
    cloudTherapyDataPublishEnabled: ['True', 'False'],
    logLevel: ['Debug', 'Info', 'Warning', 'Error'],
    emrEnabled: ['True', 'False'],
    emrDataFormat: ['Prismax_V2', 'Prismax_V3', 'HL7'],
    emrUseThirdPartyCert: ['True', 'False'],
    emrTlsEnabled: ['True', 'False'],
    emrDataBufferingEnabled: ['True', 'False'],
    hl7Connectivity: ['True', 'False'],
    serialFeatureEnable: ['True', 'False'],
    hl7AcceptAck: ['ER', 'AL', 'NE', 'SU'],
    hl7AppAck: ['ER', 'AL', 'NE', 'SU'],
    boolen_true_capital: 'TRUE',
    boolen_true: 'True',
    boolen_false: 'False',
    emrDataFormat_capital: ['PRISMAX_V2', 'PRISMAX_V3', 'HL7'],
    logLevel_capital: ['DEBUG', 'INFO', 'WARNING', 'ERROR'],
    rvEnable: ['True', 'False'],
    rvMaxConcurrentSession: ['1', '2', '3', '4', '5'],
  },
  messageTimeout: 5000,
}
