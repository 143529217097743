import { render, staticRenderFns } from "./customers.vue?vue&type=template&id=1138b8c0&"
import script from "./customers.vue?vue&type=script&lang=js&"
export * from "./customers.vue?vue&type=script&lang=js&"
import style0 from "./customers.vue?vue&type=style&index=0&id=1138b8c0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../.yarn/__virtual__/vue-loader-virtual-e4a21ae091/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../.yarn/__virtual__/vuetify-loader-virtual-af7728eaca/0/cache/vuetify-loader-npm-1.4.3-e983bc448f-837369063b.zip/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCol,VCombobox,VDialog,VRow,VSnackbar,VTextField})
